'use client';

import React from 'react';
import { PostOrPage } from '@tryghost/content-api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { SearchBlogType } from '@/config/type';
import { useSearch } from '@/contexts/searchContext';
export default function PostSearch(props: {
  post: PostOrPage;
  className?: string;
  isAppTraffic?: boolean;
  isMobile: any;
  searchResult: any;
  isSearching: any;
  isSearchLoading: any;
  setIsSearchLoading: any;
  searchBlog: any;
  tags: { id: number; name: string }[];
  // html: string;
}) {
  const post = props.post;

  const { setSearchText, searchBlog } = useSearch();

  // const { extractedTitle } = useCheerioTitleRemoval(JSON.stringify(props.html));

  return (
    <a
      key={post.id}
      href={`/blogs/${post.slug}`}
      className={[
        `bg-white`,
        `shadow-md`,
        `drop-shadow-lg`,
        `rounded-[0.75em]`,
        `text-[#141414]`,
        `relative`,
        props.className || ``,
      ].join(` `)}
    >
      {/* blog image */}
      <div
        style={{
          backgroundSize: `cover`,
          backgroundImage: `url('${post.feature_image}')`,
        }}
        className={[`pb-[53%]`, `rounded-[0.75em]`].join(` `)}
      ></div>

      {/* blog title */}
      <div className={[`text-xl`, `font-bold`, `px-4`, `py-3`].join(` `)}>
        {post.title}
      </div>

      {/* blog tags */}
      <div
        className={[
          `text-[0.8em]`,
          `px-4`,
          `flex`,
          `flex-wrap`,
          `gap-x-2`,
          `gap-y-1`,
          `uppercase`,
        ].join(` `)}
      >
        {post.tags
          ?.filter((p, i) => i <= 3)
          .map((tag: any) => (
            <button
              onClick={(event) => {
                event.preventDefault();

                setSearchText(tag.name); // Set the search text
                searchBlog(tag.name);
              }}
              className="bg-[#E7E7F7]
            text-black
            border border-[#C4C3D4] p-1 rounded-xl"
              key={tag.id}
            >
              <span
                className="bg-[#E7E7F7]
               text-black
            p-1 rounded-xl"
              >
                {tag.name}
              </span>
            </button>
          ))}
      </div>

      <div className="pt-3" />

      {/* blog description */}
      <div
        className={[
          `text-md`,
          `px-4`,
          `line-clamp-3`,
          `overflow-hidden`,
          `mb-10`,
        ].join(` `)}
      >
        {post.custom_excerpt}
      </div>

      <br />

      <div className="flex justify-end fixed bottom-0 right-0">
        <button className="bg-accent-yellow rounded-2xl p-2 m-2 flex items-center space-x-2">
          <div>Continue Reading</div>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </a>
  );
}
