import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const PinnedBlogs = ({ data }: any) => {
  return (
    <>
      <a
        href={data.link}
        className={[
          `bg-white`,
          `shadow-md`,
          `drop-shadow-lg`,
          `rounded-[0.75em]`,
          `text-[#141414]`,
        ].join(` `)}
      >
        <div
          style={{
            backgroundSize: `cover`,
            backgroundImage: `url(${data.img})`,
          }}
          className={[`pb-[53%]`, `rounded-[0.75em]`].join(` `)}
        ></div>
        <div className={[`text-xl`, `font-bold`, `px-4`, `py-3`].join(` `)}>
          {data.title}
        </div>
        <div
          className={[
            `text-[0.8em]`,
            `px-4`,
            `flex`,
            `flex-wrap`,
            `gap-x-2`,
            `gap-y-1`,
            `uppercase`,
          ].join(` `)}
        >
          {data.tags?.map((tag: any, index: any) => (
            <span
              className="bg-[#E7E7F7]
            text-black
            border border-[#C4C3D4] p-1 rounded-xl"
              key={index}
            >
              {tag}
            </span>
          ))}
        </div>
        <div className="pt-3" />
        <div
          className={[
            `text-md`,
            `px-4`,
            `line-clamp-3`,
            `overflow-hidden`,
            `mb-10`,
          ].join(` `)}
        >
          {data.description}
        </div>
        <br />
        <div className="flex justify-end fixed bottom-0 right-0">
          <button className="bg-accent-yellow rounded-2xl p-2 m-2 flex items-center space-x-2">
            <div>Continue Reading</div>
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
        {` `}
      </a>
    </>
  );
};

export default PinnedBlogs;
