/* eslint-disable @next/next/no-img-element */
'use client';

import { cn } from '@/utils';
import { useMobileResponsive } from '@/hooks';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import Link from 'next/link';

const LatestBlogSlider = ({ posts }: any) => {
  const { isMobile, windowWidth } = useMobileResponsive();
  return (
    <div className=" mt-7 flex overflow-hidden justify-center items-center">
      <Splide
        options={{
          type: `loop`,
          // padding: isMobile ? `0px` : `2rem`,
          easing: `cubic-bezier(0, 0.55, 0.45, 1)`,
          drag: true,
          lazyLoad: `nearby`,
          perPage: isMobile ? 1 : 2,
          focus: `center`,
          trimSpace: false,
          pagination: false,
          classes: {
            arrows: `custom-splide-arrows`,
            arrow: `splide__arrow`, // Ensure this matches the CSS class
            prev: `splide__arrow--prev`, // Ensure this matches the CSS class
            next: `splide__arrow--next`, // Ensure this matches the CSS class
            focus: `focus-css-splide`,
          },
        }}
      >
        {posts.map((data: any) => (
          <SplideSlide key={data.slug}>
            <Link href={`blogs/${data.slug}`} className="">
              <div
                className={cn(
                  `max-w-6xl cursor-pointer flex justify-center relative mx-3 items-center h-[300px] md:h-[500px]`,
                )}
              >
                <>
                  <img
                    className={cn(
                      `rounded-3xl w-[500px]  sm:w-full h-full object-cover`,
                      { 'w-[350px]': windowWidth < 440 },
                      { 'w-[300px]': windowWidth < 370 },
                    )} // Ensure the image scales to fit the container
                    alt={data.feature_image_alt}
                    src={data.feature_image}
                  />

                  <div
                    className={cn(
                      `
                  w-[500px]  sm:w-full
              flex justify-between items-center absolute bg-[#00000058]
               bottom-0 rounded-b-3xl h-20 p-2 space-x-3`,
                      { 'w-[350px]': windowWidth < 440 },
                      { 'w-[300px]': windowWidth < 370 },
                    )}
                  >
                    <div className="line-clamp-2 text-white">{data.title}</div>
                    <button className="bg-accent-yellow rounded-2xl flex items-center min-w-fit h-2/4 p-2">
                      Read Blog
                    </button>
                  </div>
                </>
              </div>
            </Link>
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
};

export default LatestBlogSlider;
