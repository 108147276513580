/* eslint-disable @typescript-eslint/quotes */
'use client';

import { cn } from '@/utils';
import {
  faCircleNotch,
  faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
// import backGroundImg from '../assets/searchBg.webp';
import backGroundImg from '../../pages/blogs/assets/searchBg.webp';
import { SearchBlogType } from '@/config/type';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useScroll } from '@/hooks';
import { useSearch } from '@/contexts/searchContext';

const SearchBlogComp = ({
  searchResult,
  isSearching,
  isSearchLoading,
  setIsSearchLoading,
  searchBlog,
  isAppTraffic,
  isMobile,
  tags,
}: SearchBlogType) => {
  const [activeTag, setActiveTag] = useState(``);
  // const [searchText, setSearchText] = useState(``);
  const { searchText, setSearchText } = useSearch();

  const { insideBounds } = useScroll(200, 2500);

  React.useEffect(() => {
    const tagname = localStorage.getItem('tagname');
    if (tagname) {
      setSearchText(tagname);
      searchBlog(tagname);
      localStorage.removeItem('tagname');
    }
  }, [searchBlog, setSearchText]);

  return (
    <>
      <div
        className={cn(`bg-[#9D77EE] px-2 md:px-20 py-2 md:py-10`)}
        style={{
          backgroundSize: `cover`,
          backgroundRepeat: `no-repeat`,
          backgroundPosition: `center center`,
          backgroundImage: `url(${backGroundImg.src}`,
        }}
      >
        {!isAppTraffic && (
          <>
            <div
              className={cn(
                `md:flex  justify-center items-center mt-20 px-1 md:px-6 my-2 md:my-4 block`,
                {
                  flex: !isMobile,
                },
              )}
            >
              <div className="text-center">
                <h1 className="font-black text-2xl sm:text-4xl">LSM Blogs</h1>

                {!isMobile && (
                  <p>
                    Discover articles on meditation, mindfulness, workout,
                    sleep, productivity, and much more.
                  </p>
                )}
              </div>
            </div>
          </>
        )}

        <div
          className={cn(
            `py-2 text-center flex justify-center items-center
          `,
            {
              'py-1 rounded-lg': searchResult.length !== 0 || isSearching,
            },
          )}
        >
          <form
            className="flex flex-wrap gap-x-4 gap-y-2 px-1 md:px-6  mt-1 md:mt-4 w-full max-w-6xl items-center "
            onSubmit={async (ev) => {
              try {
                ev.preventDefault();
                setIsSearchLoading(true);
                const formData = new FormData(ev.currentTarget);
                await searchBlog((formData.get(`query`) as string) || ``);
              } catch (err) {
              } finally {
                setIsSearchLoading(false);
              }
            }}
          >
            <input
              name="query"
              type="search"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              required
              placeholder={`Enter Keywords`}
              className={cn(
                `grow px-4 py-2 rounded-2xl font-bold placeholder:font-normal placeholder:text-black`,
              )}
            />

            {!isMobile && (
              <button
                className={[
                  `bg-accent-yellow`,
                  `text-black`,
                  `px-4`,
                  `py-2`,
                  `rounded-2xl`,
                  `font-bold`,
                  `border`,
                  `border-[#7356e8]`,
                ].join(` `)}
              >
                {isSearchLoading ? (
                  <FontAwesomeIcon spin icon={faCircleNotch} />
                ) : (
                  <>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                    Search
                  </>
                )}
              </button>
            )}
          </form>
        </div>

        {!isMobile && (
          <div className="flex flex-wrap justify-center">
            {tags.map((i) => (
              <>
                <div
                  onClick={() => {
                    searchBlog(i.name);
                    setSearchText(i.name);
                    setActiveTag(i.name);
                  }}
                  className={cn(
                    `bg-white m-1 sm:m-2 p-1 px-2 sm:p-2 sm:px-4 w-fit rounded-full cursor-pointer border-2 border-dark-purple`,
                    {
                      'bg-dark-purple text-white': activeTag === i.name,
                    },
                  )}
                >
                  {i.name}
                </div>
              </>
            ))}
          </div>
        )}
      </div>

      {isMobile && (
        <>
          <div
            className={cn(`overflow-x-hidden pt-2`, {
              'fixed top-20 z-50 bg-[#f2efff] py-1': insideBounds,
            })}
          >
            <Splide
              options={{
                type: `loop`,
                easing: `cubic-bezier(0, 0.55, 0.45, 1)`,
                drag: true,
                lazyLoad: `nearby`,
                trimSpace: false,
                pagination: false,
                autoWidth: true,
                classes: {
                  arrows: `splide__arrow__none`,
                },
              }}
            >
              {tags.map((i) => (
                <SplideSlide key={i.id}>
                  <div
                    key={i.id}
                    onClick={() => {
                      searchBlog(i.name);
                      +setSearchText(i.name);
                      setActiveTag(i.name);
                    }}
                    className={cn(
                      `bg-white m-1 sm:m-2 p-1 px-4 sm:p-2 sm:px-4 rounded-full cursor-pointer border border-dark-purple`,
                      {
                        'bg-dark-purple text-white': activeTag === i.name,
                      },
                    )}
                    style={{ whiteSpace: `nowrap`, display: `inline-block` }}
                  >
                    {i.name}
                  </div>
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </>
      )}
    </>
  );
};

export default SearchBlogComp;
